import listPage from "./listPage";
export default function init(componentInitiators) {
  componentInitiators.high.push(searchPage);
  listPage(componentInitiators);
}
function searchPage() {
  const element = document.getElementsByClassName("search-page")[0];
  if (!element) return;
  const searchForm = element.getElementsByClassName("search-form")[0];
  const searchInput = searchForm.getElementsByClassName("search-form__input")[0];
  searchForm.addEventListener("submit", preventEmptySearch);
  bindSubmitOnSortInputs();
  function preventEmptySearch(event) {
    if (!searchInput.value.trim()) {
      event.preventDefault();
    }
  }
  function bindSubmitOnSortInputs() {
    const sortInputs = [document.getElementById("sort-by-relevance"), document.getElementById("sort-by-date")];
    for (let i = 0; i < sortInputs.length; ++i) {
      if (!sortInputs[i]) continue;
      sortInputs[i].addEventListener("change", onChange);
    }
    function onChange() {
      if (searchInput.value && searchInput.value.trim() !== "") {
        searchForm.submit();
      }
    }
  }
}