import { checkConsentForVendor } from "../integration/didomi";
import consentPrompt from "./consentPrompt";
export default function init() {
  let context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const elements = context.getElementsByClassName("html-widget");
  for (let i = 0; i < elements.length; ++i) {
    htmlWidget(elements[i]);
  }
}
function htmlWidget(element) {
  const vendorId = element.dataset.vendor;
  if (!vendorId) return;
  checkConsentForVendor(vendorId, consent => {
    if (consent) return;
    consentPrompt({
      target: element.firstElementChild,
      vendorId
    });
  });
}