import slider from "@exp/components/slider/slider.mjs";
export default function setup() {
  let context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const params = {
    container: "livetv-widget",
    itemContainer: "slider__items",
    controls: "slider__control",
    autoplay: false,
    trackingLabel: "livetvWidget",
    alignLastItemToRight: true
  };
  const containers = context.getElementsByClassName(params.container);
  if (!containers) return;
  for (let i = 0; i < containers.length; ++i) {
    livetvWidget(containers[i], params);
  }
}
function livetvWidget(container, params) {
  const _slider = slider(container, params);
  if (!_slider) return;
  const controlButtons = container.getElementsByClassName(params.controls);
  const sliderItems = container.getElementsByClassName("slider__item");
  const sliderLinks = container.getElementsByTagName("a");
  let touched = false;
  for (let i = 0; i < sliderLinks.length; i++) {
    sliderLinks[i].addEventListener("click", trackVideoLinkByIndex.bind(false, i + 1));
  }
  _slider.onUpdate(updateWidget);
  if (window.CHANNEL_TABLET || window.CHANNEL_DESKTOP || window.CHANNEL_RESPONSIVE) {
    if (!controlButtons.length) return;
    controlButtons[0].addEventListener("click", _slider.slideToPrevious);
    controlButtons[1].addEventListener("click", _slider.slideToNext);
  }
  function updateWidget(currentIndex, previousIndex) {
    if (window.CHANNEL_TABLET || window.CHANNEL_DESKTOP || window.CHANNEL_RESPONSIVE) {
      if (!touched) {
        touched = true;
        controlButtons[0].classList.remove("hidden");
      }
    }
    const direction = currentIndex < previousIndex ? "left" : "right";
    lazyLoadImage(currentIndex, direction);
  }
  function lazyLoadImage(currentIndex, direction) {
    if (direction === "left") return;
    const nextIndex = currentIndex + 1;
    const nextSlide = sliderItems[nextIndex];
    if (!nextSlide) return;
    const lazyImages = nextSlide.querySelectorAll("img[loading='lazy']");
    for (let i = 0; i < lazyImages.length; i++) {
      lazyImages[i].loading = "eager";
    }
  }
  function trackVideoLinkByIndex(index) {
    window.ExpressenAnalytics = window.ExpressenAnalytics || [];
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("action", "click", `Top TV Slider-video (${index})`);
    });
  }
}