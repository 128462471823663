import videoPlayer from "@exp/scripts/plugins/video-player";
export default function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  let lazy = arguments.length > 1 ? arguments[1] : undefined;
  videoPlayer(scope, lazy, getStickyOptions);
}
function getStickyOptions() {
  if (window.CHANNEL_MOBILE) {
    return {
      stickyStyle: {
        left: "16px",
        right: "16px"
      }
    };
  } else {
    return {
      alignToXSelector: ".site-body__column-3"
    };
  }
}