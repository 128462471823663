import { checkConsentForPurpose, checkConsentForVendorSync } from "../integration/didomi";
import storageHelper from "../helpers/storage";
export default function init() {
  const uxLite = document.getElementById("ux-lite");
  if (!uxLite) return;
  checkConsentForPurpose("analytics", purposeConsent => {
    if (!purposeConsent || !checkConsentForVendorSync("c:googleanalytics")) return uxLite.remove();
    const storage = storageHelper("localStorage");
    if (!storage || storage && storage.getItem("ux-lite")) return uxLite.remove();
    uxLite.classList.remove("hidden");
    const form = uxLite.getElementsByTagName("form")[0];
    const inputs = uxLite.querySelectorAll("input[type='range']");
    const submitButton = uxLite.querySelector("input[type='submit']");
    const closeButton = uxLite.querySelector("button.close");
    const submitResponse = uxLite.getElementsByClassName("submit-response")[0];
    const formHeight = form.getBoundingClientRect().height;
    submitButton.addEventListener("click", evt => {
      evt.preventDefault();
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("ux-lite", "vote - ease", inputs[0].value);
        analytics.track.customActive("ux-lite", "vote - usefulness", inputs[1].value);
      });
      submitResponse.setAttribute("style", `height:${formHeight}px;`);
      submitResponse.classList.remove("hidden");
      storage.setItem("ux-lite", "sent");
      form.remove();
    });
    closeButton.addEventListener("click", evt => {
      evt.preventDefault();
      uxLite.classList.add("hidden");
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("ux-lite", "close");
      });
      storage.setItem("ux-lite", "closed");
    });
  });
}