import getWindowInnerHeight from "../helpers/getWindowInnerHeight";
import scroll from "@exp/scripts/helpers/scroll";
export default function init(scope) {
  const stickySlideshows = [...scope.getElementsByClassName("sticky-slideshow")];
  if (!stickySlideshows.length) return;
  scroll.add(_ref => {
    let {
      scrollY,
      lastScrollY
    } = _ref;
    const windowInnerHeight = getWindowInnerHeight();
    const fastScroll = Math.abs(scrollY - lastScrollY) > windowInnerHeight / 2;
    if (!fastScroll) return;
    const {
      top: firstSlideshowTop
    } = stickySlideshows[0].getBoundingClientRect();
    const allSlideshowsBelow = firstSlideshowTop > windowInnerHeight;
    let allSlideshowsAbove;
    if (!allSlideshowsBelow) {
      const {
        bottom: lastSlideshowBottom
      } = stickySlideshows[stickySlideshows.length - 1].getBoundingClientRect();
      allSlideshowsAbove = lastSlideshowBottom < 0;
    }
    stickySlideshows.forEach(slideshow => {
      if (allSlideshowsBelow) {
        slideshow.getElementsByClassName("visible")[0].classList.remove("visible");
        slideshow.children[0].children[0].classList.add("visible");
      } else if (allSlideshowsAbove) {
        const images = slideshow.children[0].children;
        slideshow.getElementsByClassName("visible")[0].classList.remove("visible");
        images[images.length - 1].classList.add("visible");
      }
    });
  });
  let imageHeight;
  stickySlideshows.forEach(slideshow => {
    imageHeight = slideshow.querySelector(".rich-image").getBoundingClientRect().height;
    const imageContainer = slideshow.querySelector(".slideshow-imagecontainer");
    imageContainer.setAttribute("style", `height: ${parseInt(imageHeight)}px`);
    setupIntersectionObserver(slideshow);
  });
  function setupIntersectionObserver(slideshow) {
    const slideshowTextElements = slideshow.getElementsByClassName("slideshow-text");
    if (!slideshowTextElements.length) return;
    const observer = new IntersectionObserver(changeSlideshowImage, {
      threshold: 0.2,
      rootMargin: `-${imageHeight}px 0px 0px`
    });
    for (let i = 0; i < slideshowTextElements.length; ++i) {
      observer.observe(slideshowTextElements[i]);
    }
    function changeSlideshowImage(entries) {
      entries.forEach(entry => {
        const slideshowImages = [...slideshow.getElementsByClassName("slideshow-imagecontainer")[0].children];
        if (!slideshowImages.length) return;
        const imageIndex = parseInt(entry.target.dataset.index);
        if (!entry.isIntersecting && entry.boundingClientRect.top <= imageHeight) {
          const nextImageToShow = slideshowImages[imageIndex + 1];
          if (nextImageToShow) {
            slideshowImages.forEach(image => {
              image.classList.remove("visible");
            });
            nextImageToShow.classList.add("visible");
          }
        } else if (entry.isIntersecting && entry.boundingClientRect.top <= imageHeight) {
          slideshowImages.forEach(image => {
            image.classList.remove("visible");
          });
          slideshowImages[parseInt(entry.target.dataset.index)].classList.add("visible");
        }
      });
    }
  }
}