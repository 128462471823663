import throttle from "../helpers/throttle";
const MILESTONE_COUNT = 5;
const MILESTONE_SIZE = 100 / MILESTONE_COUNT;
export default function trackScroll() {
  const milestones = new Array(MILESTONE_COUNT);
  function calculateScroll() {
    const currentMilestone = Math.floor(calcPercent() / MILESTONE_SIZE) - 1;
    for (let i = 0; i <= currentMilestone; i++) {
      if (!milestones[i]) {
        milestones[i] = true;
        track((i + 1) * MILESTONE_SIZE);
      }
    }
  }
  window.addEventListener("scroll", throttle(calculateScroll, 100));
}
function track(percent) {
  window.dataLayer.push({
    event: "article scroll",
    eventParams: {
      category: "article",
      scrollDepth: `${percent}`,
      scrollMeasure: "percent"
    }
  });
  window.ExpressenAnalytics.push(analytics => {
    analytics.track.scroll(percent);
  });
}
function calcPercent() {
  const top = window.pageYOffset;
  const scrollHeight = document.documentElement.scrollHeight;
  const viewportHeight = window.innerHeight;
  return top / (scrollHeight - viewportHeight) * 100;
}