import storageHelper from "../helpers/storage";
const storage = storageHelper("localStorage");
export default function init(scope) {
  scope = scope || document;
  const pollWidgets = scope.getElementsByClassName("poll");
  if (!pollWidgets.length) return;
  for (let i = 0; i < pollWidgets.length; i++) {
    setupPoll(pollWidgets[i]);
  }
}
function setupPoll(poll) {
  const voteButton = poll.getElementsByClassName("poll__vote")[0];
  const pollUrl = window.Exp && window.Exp.config && window.Exp.config.pollsApiUrl;
  const pollUid = poll.dataset.uid;
  if (!voteButton || !pollUrl || !pollUid) return;
  const hasCrossedWasp = !!poll.getElementsByClassName("poll__label--crossed")[0];
  const hasAlreadyVoted = storage && storage.getItem(`poll-${pollUid}`);
  let resultHasBeenShown = false;
  if (!hasAlreadyVoted) {
    poll.addEventListener("change", enableVoting);
  }
  const showResultButton = poll.getElementsByClassName("poll__show-result")[0];
  if (showResultButton) {
    showResultButton.addEventListener("click", event => {
      event.preventDefault();
      if (!resultHasBeenShown) {
        return getResult(`${pollUrl}/poll/${pollUid}`).then(pollResults => {
          pollResults = JSON.parse(pollResults);
          insertSubmitData(pollResults);
          toggleResult();
          resultHasBeenShown = true;
        }).catch();
      } else {
        toggleResult();
      }
    });
  }
  const backToPollButton = poll.getElementsByClassName("poll__back-to-poll")[0];
  if (!backToPollButton) return;
  backToPollButton.addEventListener("click", toggleResult);
  function enableVoting() {
    voteButton.disabled = false;
    poll.addEventListener("submit", event => {
      event.preventDefault();
      const votingValue = poll.elements.answerUid.value;
      if (!votingValue) return;
      const payload = JSON.stringify({
        alternativeId: votingValue
      });
      return fetch(`${pollUrl}/poll/${pollUid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: payload
      }).then(response => {
        if (response && response.status === 200) {
          return response.text();
        }
      }).then(pollResults => {
        pollResults = JSON.parse(pollResults);
        voteButton.disabled = true;
        insertSubmitData(pollResults);
        resultHasBeenShown = true;
        toggleResult();
        if (storage) {
          storage.setItem(`poll-${pollUid}`, votingValue);
        }
      }).catch();
    });
    poll.removeEventListener("change", enableVoting);
  }
  function insertSubmitData(pollResults) {
    const resultString = getResultString(pollResults.startDate, pollResults.numberOfVotes);
    const submitMessageContainer = poll.getElementsByClassName("poll__submit-message")[0];
    if (submitMessageContainer) {
      submitMessageContainer.textContent = resultString + submitMessageContainer.textContent;
    }
    const resultList = poll.getElementsByClassName("poll__results")[0];
    if (resultList) {
      if (resultHasBeenShown) {
        resultList.innerHTML = "";
      }
      const resultMarkup = buildResultMarkup(pollResults.results, hasCrossedWasp);
      resultList.appendChild(resultMarkup);
    }
  }
  function toggleResult() {
    const questionnaire = poll.getElementsByClassName("poll__questionnaire")[0];
    const resultContainer = poll.getElementsByClassName("poll__result-container")[0];
    const pollButtons = poll.getElementsByClassName("poll__buttons")[0];
    if (!questionnaire || !resultContainer) return;
    pollButtons.classList.toggle("hidden");
    questionnaire.classList.toggle("hidden");
    resultContainer.classList.toggle("hidden");
  }
}
function getResult(pollResultUrl) {
  return fetch(pollResultUrl).then(response => {
    if (response && response.status === 200) {
      return response.text();
    }
  }).catch();
}
function buildResultMarkup(results, hasCrossedWasp) {
  const fragment = document.createDocumentFragment();
  for (let i = 0; i < results.length; i++) {
    const resultPercentage = results[i].percent;
    const percentageText = `${resultPercentage}%`;
    const listItem = document.createElement("li");
    const percentageContainer = document.createElement("span");
    const answerContainer = document.createElement("div");
    const percentageBar = document.createElement("div");
    listItem.classList.add("poll__result");
    answerContainer.classList.add("poll__answer");
    if (!hasCrossedWasp) {
      answerContainer.textContent = results[i].alternative;
    } else if (i === results.length - 1) {
      answerContainer.innerHTML = "<svg><use href='#crossed-wasp'></svg>";
    }
    percentageContainer.classList.add("poll__percentage");
    percentageContainer.textContent = percentageText;
    percentageBar.classList.add("poll__bar", "th-text--main");
    percentageBar.style.background = `linear-gradient(90deg, currentColor ${percentageText}, #f7f6f6 ${percentageText})`;
    answerContainer.appendChild(percentageBar);
    listItem.appendChild(percentageContainer);
    listItem.appendChild(answerContainer);
    fragment.appendChild(listItem);
  }
  return fragment;
}
function getResultString(startDate, numberOfVotes) {
  if (!startDate || !numberOfVotes) return "";
  return `Frågan startades ${startDate}. Totalt har ${numberOfVotes} personer svarat på frågan.`;
}