import scroll from "@exp/scripts/helpers/scroll";
import slider from "@exp/components/slider/slider.mjs";
import { pushFurtherReadingEvent } from "@exp/scripts/integration/gtm";
export default function readMoreSlider(context) {
  let trackingParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const container = context && context.getElementsByClassName("read-more-slider")[0];
  if (!container) return;
  scroll.add(impressionTracking);
  initSliderClickTracking(container);
  slider(container, {
    itemContainer: "slider__items",
    controls: "slider__control",
    trackingLabel: "read-more-slider",
    alignLastItemToRight: true
  });
  function impressionTracking() {
    if (scroll.isContainerInView(container)) {
      scroll.remove(impressionTracking);
      pushFurtherReadingEvent(trackingParams, "impression");
    }
  }
  function initSliderClickTracking() {
    const links = container.querySelectorAll(".slider__items a");
    if (!links.length) return;
    [...links].forEach((link, i) => {
      link.addEventListener("click", () => {
        if (link.href) {
          const titleElement = link.querySelector("h4");
          const title = titleElement ? titleElement.innerText.trim() : "";
          pushFurtherReadingEvent({
            title,
            url: link.href,
            index: i + 1,
            ...trackingParams
          }, "read more slider");
        }
      });
    });
  }
}