import slider from "@exp/components/slider/slider.mjs";
export default function setup() {
  let context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const params = {
    container: "rotator",
    itemContainer: "slider__items",
    controls: "slider__control",
    autoplay: true,
    alignLastItemToRight: true,
    trackingLabel: "rotator"
  };
  const containers = context.getElementsByClassName(params.container);
  if (!containers) return;
  for (let i = 0; i < containers.length; ++i) {
    rotator(containers[i], params, i);
  }
}
function rotator(container, params, index) {
  const paramsClone = Object.assign({}, params);
  paramsClone.trackingLabel = `${paramsClone.trackingLabel}-${index + 1}`;
  slider(container, paramsClone);
}