import livetvWidget from "../plugins/livetvWidget";
import * as ecommerce from "../integration/ecommerceTracking";
import uxLiteForm from "../plugins/uxLiteForm";
export default function init(componentInitiators) {
  premiumWidgetTracker();
  componentInitiators.high.forEach(fn => fn());
  livetvWidget();
  componentInitiators.medium.forEach(fn => fn());
  uxLiteForm();
}
function premiumWidgetTracker() {
  const mainColumn = document.getElementsByClassName("site-body__column-2")[0];
  if (!mainColumn) return;
  const user = window.Exp && window.Exp.user;
  if (user && user.entitlements && user.entitlements.length) return;
  const mainColumnPremiumLinks = mainColumn.getElementsByClassName("premium-link");
  trackLinkClick(mainColumnPremiumLinks);
  if (window.CHANNEL_TABLET || window.CHANNEL_DESKTOP || window.CHANNEL_RESPONSIVE) {
    const complementaryColumn = document.getElementsByClassName("site-body__column-3")[0];
    const complementaryPremiumLinks = complementaryColumn && complementaryColumn.getElementsByClassName("premium-link");
    trackLinkClick(complementaryPremiumLinks);
  }
  function trackLinkClick(links) {
    if (!links || !links.length) return;
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", ecommerce.productClick);
    }
  }
}