import videoPlayer from "./videoPlayer";
export default function setup(container) {
  container = container || document;
  const containers = container.getElementsByClassName("container-widget");
  if (!containers) return;
  for (let i = 0; i < containers.length; ++i) {
    containerWidgetTabs(containers[i]);
  }
}
function containerWidgetTabs(container) {
  const tabClass = "container-widget__tab";
  const tabButtons = container.getElementsByClassName(tabClass);
  if (!tabButtons.length) return;
  const tabContentClass = "container-widget__tab-content";
  const tabContentElements = container.getElementsByClassName(tabContentClass);
  if (!tabContentElements.length) return;
  let currentButton = tabButtons[0];
  let currentContent = tabContentElements[0];
  for (let i = 0; i < tabButtons.length; i++) {
    tab(tabButtons[i], tabContentElements[i]);
  }
  function tab(tabButton, tabContent) {
    let hasBeenRendered = false;
    tabButton.addEventListener("click", () => {
      trackTabInteraction(tabButton.innerHTML.trim());
      showTab();
    });
    function showTab() {
      if (tabButton.classList.contains(`${tabClass}--current`)) return;
      if (hasBeenRendered) {
        showTabContent();
      } else {
        hasBeenRendered = true;
        renderAndShowTabContent();
      }
    }
    function renderAndShowTabContent() {
      const templates = tabContent.getElementsByTagName("template");
      if (!templates) return;
      for (let i = 0; i < templates.length; i++) {
        const templateNode = document.importNode(templates[i].content, true);
        tabContent.appendChild(templateNode);
        videoPlayer(tabContent, true);
        setup(tabContent);
      }
      showTabContent();
    }
    function showTabContent() {
      if (currentContent) {
        currentContent.classList.remove(`${tabContentClass}--current`);
      }
      if (currentButton) {
        currentButton.classList.remove(`${tabClass}--current`);
      }
      currentButton = tabButton;
      currentContent = tabContent;
      tabButton.classList.add(`${tabClass}--current`);
      tabContent.classList.add(`${tabContentClass}--current`);
    }
    function trackTabInteraction(tabName) {
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("action", "click", `tab ${tabName}`);
      });
    }
  }
}