import lazyLoadAds from "../helpers/lazyLoadAds";
import outsiderVisibleWidth from "../helpers/outsiderVisibleWidth";
export default function outsiderAds(initFromResize) {
  if (!outsiderVisibleWidth.matches) {
    if (outsiderVisibleWidth.addEventListener) outsiderVisibleWidth.addEventListener("change", reInit);
    return;
  }
  const container = document.getElementsByClassName("outsider-ads")[0];
  if (!container) return;
  const adSlots = container.getElementsByClassName("outsider-ads__ad-slot");
  const adSlotsCount = adSlots.length;
  if (!adSlotsCount) return;
  for (let i = 0; i < adSlotsCount; i++) {
    const adSlot = adSlots[i];
    adSlot.classList.add("bam-ad-slot");
  }
  if (initFromResize) {
    lazyLoadAds(container);
  }
}
function reInit() {
  if (outsiderVisibleWidth.removeEventListener) outsiderVisibleWidth.removeEventListener("change", reInit);
  outsiderAds(true);
}