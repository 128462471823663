export default function init(storageType) {
  let storage;
  try {
    storage = window[storageType];
    if (!storage) return;
    return {
      getItem,
      setItem,
      removeItem
    };
  } catch (ignored) {
    return;
  }
  function getItem(key) {
    try {
      return storage.getItem(key);
    } catch (e) {
      return;
    }
  }
  function setItem(key, value) {
    try {
      return storage.setItem(key, value);
    } catch (e) {
      return;
    }
  }
  function removeItem(key) {
    try {
      storage.removeItem(key);
    } catch (e) {
      return;
    }
  }
}