import scroll from "@exp/scripts/helpers/scroll";
import getWindowInnerHeight from "./getWindowInnerHeight";
const observers = [];
const loadOffset = 200;
const useIntersectionObserver = ("IntersectionObserver" in window);
export default function lazyLoadAds(scope, infinityScroll) {
  if (!scope) return;
  window.Bau.push(() => {
    const observerIndex = Number(infinityScroll);
    if (!observers[observerIndex]) {
      observers[observerIndex] = useIntersectionObserver ? setupIntersectionObserver(infinityScroll) : setupScrollObserver(infinityScroll);
    }
    const observer = observers[observerIndex];
    const adSlots = scope.getElementsByClassName("bam-ad-slot");
    for (let i = 0; i < adSlots.length; i++) {
      const adSlot = adSlots[i];
      observer.observe(adSlot);
    }
  });
}
function setupIntersectionObserver(infinityScroll) {
  const observer = new IntersectionObserver(viewportUpdate, {
    rootMargin: `${loadOffset}px 0px`
  });
  return observer;
  function viewportUpdate(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        displayAd(entry.target, infinityScroll);
      }
    });
  }
}
function setupScrollObserver(infinityScroll) {
  const entries = [];
  scroll.add(() => viewportUpdate(), true);
  return {
    observe(entry) {
      entries.push(entry);
      viewportUpdate([entry]);
    }
  };
  function viewportUpdate() {
    let currentEntries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : entries;
    const toRemove = [];
    for (let i = 0; i < currentEntries.length; i++) {
      const entry = currentEntries[i];
      if (!isCloseToView(entry)) continue;
      toRemove.push(entry);
      displayAd(entry, infinityScroll);
    }
    for (let i = 0; i < toRemove.length; i++) {
      const index = entries.indexOf(toRemove[i]);
      if (index === -1) continue;
      entries.splice(index, 1);
    }
  }
  function isCloseToView(adSlot) {
    const bottomOffset = getWindowInnerHeight() + loadOffset;
    const {
      top,
      bottom
    } = adSlot.getBoundingClientRect();
    return bottom >= -loadOffset && top <= bottomOffset;
  }
}
function displayAd(adSlot, infinityScroll) {
  const config = {};
  if (infinityScroll) {
    config.infinityScroll = true;
    config.targeting = {
      tags: splitAdMeta(adSlot.dataset.targetingTags),
      viz: splitAdMeta(adSlot.dataset.targetingViz)
    };
  }
  window.Bau.displayAds(adSlot, config);
}
function splitAdMeta(str) {
  if (!str) return [];
  return str.split("#");
}