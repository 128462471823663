import { checkConsentForPurpose } from "../integration/didomi";
import storageHelper from "../helpers/storage";
const storage = storageHelper("localStorage");
let userId = null;
export function get(path, sectionSpecific) {
  return makeCall(path, 7, sectionSpecific ? "others-have-read-in-section" : "recommendations");
}
export function markAsRead(path) {
  return makeCall(path, 0).catch(() => {});
}
export function handleResponse(response, nItems) {
  if (nItems === 0) return;
  if (response.status === 200) {
    const precisUserId = response.headers.get("user_id");
    if (precisUserId) {
      userId = precisUserId;
      if (storage) storage.setItem("precisUserId", precisUserId);
    }
    return response.json();
  }
}
export function makeCall(path, nItems) {
  let endpoint = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "recommendations";
  let slim = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return new Promise(resolve => {
    let apiUrl = `${window.Exp.config.precisUrl}/${endpoint}?path=${encodeURIComponent(path)}&n_items=${nItems}&slim=${slim}`;
    if (!userId && storage) {
      checkConsentForPurpose("functional", hasConsent => {
        if (hasConsent) {
          userId = userId || storage.getItem("precisUserId");
          if (userId === null) {
            apiUrl += "&new_id=true";
          }
        }
        fetchRecommendations();
      });
    } else {
      fetchRecommendations();
    }
    function fetchRecommendations() {
      apiUrl += `&user_id=${userId}`;
      resolve(fetch(apiUrl).then(response => handleResponse(response, nItems)));
    }
  });
}