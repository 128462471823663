import { pushPageView } from "@exp/scripts/integration/gtm";
export default function trackPageUnfreeze() {
  window.addEventListener("pageshow", event => {
    if (event.persisted) {
      pushPageView();
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.pageView();
      });
    }
  });
}