import resizeAd from "../helpers/resizeAd";
import tabletInsiderAds from "../plugins/tabletInsiderAds";
import outsiderAds from "../plugins/outsiderAds";
import lazyLoadAds from "../helpers/lazyLoadAds";
window.Bau = window.Bau || [];
export default function init(bamData) {
  if (!bamData) return;
  const context = document.getElementsByClassName("site-body")[0] || document.body;
  if (window.CHANNEL_TABLET || window.CHANNEL_RESPONSIVE) {
    adResizer();
  }
  if (window.CHANNEL_DESKTOP || window.CHANNEL_RESPONSIVE) {
    outsiderAds();
  }
  lazyLoadAds(context);
  function adResizer() {
    tabletInsiderAds(context);
    window.Bau.push(() => {
      resizeAd(window.Bau);
    });
  }
}