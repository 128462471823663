import * as cookies from "@exp/scripts/helpers/cookies";
import { checkConsentForPurpose, checkConsentForPurposeSync, getPurposeSync } from "../integration/didomi";
const root = document.documentElement;
const systemSettingsDark = window.matchMedia("(prefers-color-scheme: dark)");
let preference, purpose;
const disclaimers = [];
export default function init() {
  window.Exp = window.Exp || {};
  checkConsentForPurpose("functional", cookieConsent => {
    purpose = getPurposeSync("functional");
    const cookieValue = cookieConsent && cookies.getCookie("colorScheme");
    // don't include legacy cookie value (auto)
    preference = cookieValue !== "auto" && cookieValue || getAppliedScheme() || "";
    window.Exp.colorScheme = getCurrentScheme();
    const pickers = document.getElementsByClassName("color-scheme__picker");
    if (!pickers) return;
    for (let i = 0; i < pickers.length; ++i) {
      colorScheme(pickers[i], cookieConsent);
    }
    if (!cookieConsent) {
      window.Didomi.on("consent.changed", () => {
        if (!checkConsentForPurposeSync("functional")) return;
        hideDisclaimers();
        persistPreference();
      });
    }
  });
}
export function getCurrentScheme() {
  if (!preference) return systemSettingsDark.matches ? "dark" : "light";
  return preference;
}
function persistPreference() {
  if (!checkConsentForPurposeSync("functional")) return;
  if (preference) {
    cookies.setCookie("colorScheme", preference, {
      "max-age": 31556926
    });
  } else {
    cookies.removeCookie("colorScheme");
  }
  window.Exp.colorScheme = getCurrentScheme();
}
function colorScheme(picker, cookieConsent) {
  for (let i = 0; i < picker.options.length; ++i) {
    const option = picker.options[i];
    if (option.value !== preference) continue;
    option.selected = true;
    break;
  }
  picker.addEventListener("change", applyPreference);
  if (!cookieConsent && purpose) {
    const disclaimer = createDisclaimer();
    const toggleCMPButton = disclaimer.getElementsByTagName("button")[0];
    toggleCMPButton.addEventListener("click", window.Didomi.preferences.show);
    picker.parentElement.appendChild(disclaimer);
  }
}
function getAppliedScheme() {
  if (root.classList.contains("color-scheme--light")) {
    return "light";
  } else if (root.classList.contains("color-scheme--dark")) {
    return "dark";
  }
}
function createDisclaimer() {
  let disclaimer;
  if (disclaimers.length) {
    disclaimer = disclaimers[0].cloneNode(true);
  } else {
    disclaimer = document.createElement("span");
    // eslint-disable-next-line prefer-template
    disclaimer.insertAdjacentHTML("afterbegin", "" + "Ditt val av färgtema sparas bara om du i dina " + "<button class=\"toggle-consent-notice\">Hantera kakor</button>" + " godkänner \"" + (typeof purpose.name === "string" ? purpose.name : purpose.name.sv) + "\".");
  }
  disclaimers.push(disclaimer);
  return disclaimer;
}
function hideDisclaimers() {
  for (let i = 0; i < disclaimers.length; ++i) {
    disclaimers[i].classList.add("hidden");
  }
  disclaimers.length = 0;
}
function applyPreference(event) {
  const value = event.target.value;
  root.classList.toggle("color-scheme--light", value === "light");
  root.classList.toggle("color-scheme--dark", value === "dark");
  preference = value;
  persistPreference();
}