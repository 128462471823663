import scroll from "@exp/scripts/helpers/scroll";
export default function init() {
  const siteHeader = document.getElementsByClassName("site-header")[0];
  if (!siteHeader) return;
  const slim = siteHeader.classList.contains("site-header--slim");
  const topMenu = siteHeader.getElementsByClassName("top-menu")[0];
  let topMenuHeight = 0;
  if (!slim && topMenu) {
    topMenuHeight = topMenu.getBoundingClientRect().height;
  }
  const documentBody = document.body;
  const menuScrollBackThreshold = 20;
  let isStuck = false;
  let topMenuExpanded = !slim;
  let animate;
  scroll.add(evaluateSticky);
  if (topMenu) scroll.add(evaluateTopMenuExpanded);
  function evaluateSticky(_ref) {
    let {
      scrollY,
      lastScrollY
    } = _ref;
    if (scrollY === lastScrollY) return;
    const {
      top,
      bottom
    } = siteHeader.getBoundingClientRect();
    const belowHeader = slim ? top < 0 : bottom <= topMenuHeight;
    if (scrollY > lastScrollY && belowHeader) {
      stick();
    } else if (!belowHeader) {
      unstick();
    }
  }
  function stick() {
    documentBody.classList.add("header-stuck");
    isStuck = true;
    animate = setTimeout(() => {
      documentBody.classList.add("top-menu-animated");
      animate = undefined;
    }, 0);
  }
  function unstick() {
    if (animate) animate = clearTimeout(animate);
    documentBody.classList.remove("header-stuck");
    documentBody.classList.remove("top-menu-animated");
    isStuck = false;
  }
  function evaluateTopMenuExpanded(_ref2) {
    let {
      scrollY,
      lastScrollY
    } = _ref2;
    if (!isStuck) return;
    if (topMenuExpanded && scrollY > lastScrollY) {
      documentBody.classList.remove("top-menu-expanded");
      topMenuExpanded = false;
    } else if (!topMenuExpanded && lastScrollY - scrollY >= menuScrollBackThreshold) {
      documentBody.classList.add("top-menu-expanded");
      topMenuExpanded = true;
    }
  }
}