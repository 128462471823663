import scroll from "@exp/scripts/helpers/scroll";
import articleBottomAd from "./articleBottomAd";
import { markAsRead, get as getRecommendations } from "../integration/recommendations";
import { pushPageView, content } from "@exp/scripts/integration/gtm";
const originalPath = location.pathname;
const fetchHeaders = {};
let items;
if (window.CHANNEL_TABLET && window.expNative) {
  fetchHeaders["X-Exp-Hide-Tablet-Menu"] = "true";
}
export default function init(perArticleInit) {
  if (!window.fetch) return;
  if (["storytelling-article"].includes(window.Exp.pageTemplateName)) return;
  let spaIndex = 1;
  markAsRead(window.location.pathname);
  const container = document.getElementById("lazy-container");
  if (!container) return;
  const topArticle = document.getElementsByClassName("article")[0];
  if (!topArticle) return;
  let currentArticleLinkUrl = originalPath;
  const pendingUserScroll = getFirstUserScroll();
  getContentIds(container.dataset).then(recommendations => {
    if (!recommendations) return;
    items = recommendations.items;
    if (!items || !items.length) {
      return articleBottomAd();
    }
    pendingUserScroll.then(getNextArticle);
    scroll.add(handleScroll({
      articleElement: topArticle,
      visited: true,
      loadedScripts: true
    }));
  }).catch(onError);
  function getFirstUserScroll() {
    return new Promise(resolve => {
      function resolveFirstUserScroll() {
        window.removeEventListener("scroll", resolveFirstUserScroll);
        resolve();
      }
      window.addEventListener("scroll", resolveFirstUserScroll);
    });
  }
  function fetchArticle(contentId) {
    const contentPath = `${contentId}/`;
    return fetch(contentPath, {
      credentials: "same-origin",
      headers: fetchHeaders
    }).then(response => {
      if (response.status !== 200) return null;
      return response.text();
    });
  }
  function getNextArticle() {
    const next = items.shift();
    if (!next) return;
    return fetchArticle(next.contentId).then(articleMarkup => {
      if (articleMarkup === null) {
        return getNextArticle();
      } else {
        loadArticle(articleMarkup, !items.length);
      }
    });
  }
  function loadArticle(articleMarkup, isLast) {
    container.insertAdjacentHTML("beforeend", articleMarkup);
    const articleElement = container.lastElementChild;
    window.Bau.push(() => {
      window.Bau.preloadAds(articleElement, {
        infinityScroll: true
      });
    });
    spaIndex += 1;
    articleElement.dataset.articleIndex = spaIndex;
    articleElement.dataset.totalArticles = 8;
    if (isLast) {
      articleBottomAd();
    }
    scroll.add(handleScroll({
      articleElement,
      visited: false
    }));
  }
  function handleScroll(articleObj) {
    const {
      articleElement
    } = articleObj;
    const linkUrl = articleElement.dataset.linkUrl;
    let markedAsRead = linkUrl === originalPath;
    const screenHeight = window.innerHeight;
    const loadOffset = Math.floor(screenHeight * 1.5);
    return function () {
      if (linkUrl === currentArticleLinkUrl) return;
      const {
        top: articleTop,
        bottom: articleBottom
      } = articleElement.getBoundingClientRect();
      if (articleBottom < 0) return;
      if (articleTop < loadOffset && !articleObj.loadedScripts) {
        perArticleInit(articleElement, true);
        articleObj.loadedScripts = true;
      }
      const entireArticleVisible = articleTop >= 0 && articleBottom <= screenHeight;
      const articleCoversScreen = articleTop <= 0 && articleBottom >= screenHeight;
      if (!entireArticleVisible && !articleCoversScreen) return;
      window.history.replaceState(null, null, linkUrl);
      const seoTitle = articleElement.dataset.seoTitle;
      if (seoTitle) {
        document.title = seoTitle;
      }
      currentArticleLinkUrl = linkUrl;
      trackAdditionalPageView(articleElement);
      if (!markedAsRead) {
        markAsRead(currentArticleLinkUrl);
        markedAsRead = true;
      }
      if (articleObj.visited || !items || !items.length) return;
      getNextArticle();
      articleObj.visited = true;
    };
  }
}
function trackAdditionalPageView(articleElement) {
  const articleIndex = parseInt(articleElement.dataset.articleIndex || 1);
  let gtmContent;
  try {
    if (articleIndex === 1) {
      gtmContent = content;
    } else {
      gtmContent = JSON.parse(articleElement.getElementsByClassName("partial-gtm-content")[0].innerText);
    }
    gtmContent.location = window.location.href;
    pushPageView({
      content: {
        ...gtmContent,
        articleIndex,
        trigger: "virtual page load"
      }
    });
  } catch (err) {
    // no-op
  }
  let trackingInfo;
  if (articleElement.dataset.linkUrl === originalPath) {
    trackingInfo = window.Exp.trackingInfo;
  } else {
    const trackingInfoContainer = articleElement.getElementsByClassName("js-tracking")[0];
    if (!trackingInfoContainer) return;
    trackingInfo = JSON.parse(trackingInfoContainer.innerText);
  }
  if (!trackingInfo) return;
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  window.ExpressenAnalytics.push(api => {
    const trackingSettings = window.Exp && window.Exp.trackingSettings || {};
    api.init(trackingInfo, Object.assign(trackingSettings, {
      spaIndex: articleIndex
    }));
    api.track.pageView();
  });
}
function getContentIds(config) {
  const stack = [getRecommendationSource().catch(onError)];
  if (!("adsDisabled" in config)) {
    stack.push(getAddyArticle().catch(onError));
  }
  return Promise.all(stack).then(response => {
    let recommendations = response[0];
    const addyArticle = response[1];
    return prepend();
    function prepend() {
      if (!addyArticle) return recommendations;
      if (!recommendations) recommendations = {
        items: []
      };
      if (addyArticle) {
        recommendations.items.unshift(addyArticle);
      }
      return recommendations;
    }
  });
  function getRecommendationSource() {
    if (config.articles) {
      return Promise.resolve({
        items: config.articles.split(",").map(contentId => {
          return {
            contentId
          };
        })
      });
    }
    if (config.newsStorySource) {
      return fetch(`/news-story-next${config.newsStorySource}?currentArticle=${originalPath}`).then(response => response.status === 200 && response.json());
    }
    return getRecommendations(originalPath, "sectionSpecific" in config);
  }
}
function getAddyArticle() {
  return new Promise((resolve, reject) => {
    setTimeout(reject, 2000);
    fetch(`https://addy.expressen.se/closest-native${originalPath}`).then(response => response.status === 200 && response.json()).then(resolve).catch(reject);
  });
}
function onError() {}