import scroll from "@exp/scripts/helpers/scroll";
import stuckHeaderHeight from "../helpers/stuckHeaderHeight";
const stuckVideoHeight = window.innerWidth * (9 / 16);
export default function brandedContentBanner(article) {
  const banner = article.getElementsByClassName("branded-content__banner--sticky")[0];
  if (!banner) return;
  let isStuck = false;
  scroll.add(evaluateSticky, true);
  function evaluateSticky() {
    const siteHeader = document.querySelector(".site-header");
    const updatedStuckHeaderHeight = siteHeader ? stuckHeaderHeight : 0;
    let stuckPosition = updatedStuckHeaderHeight;
    if (window.CHANNEL_MOBILE && document.body.classList.contains("video-stuck")) {
      stuckPosition = stuckVideoHeight;
    }
    const {
      top,
      bottom
    } = article.getBoundingClientRect();
    const shouldBeStuck = top <= stuckPosition && bottom - stuckPosition > 50;
    if (isStuck === shouldBeStuck) return;
    if (shouldBeStuck) {
      stick();
    } else {
      unstick();
    }
  }
  function stick() {
    if (isStuck) return;
    isStuck = true;
    banner.classList.add("branded-content__banner--stuck");
  }
  function unstick() {
    if (!isStuck) return;
    isStuck = false;
    banner.classList.remove("branded-content__banner--stuck");
  }
}