const nativeApi = window.expNative;
const isHybridTablet = !!nativeApi;
export function setupSubscriptionOnboarding(links) {
  if (!isHybridTablet) return;
  if (!links.length) return;
  return setupLogin(links[0], links[1]);
}
export function setupNativeArticleLock(scope) {
  if (!isHybridTablet) return;
  const container = scope.getElementsByClassName("paywall-manager__fallback")[0];
  if (!container) return;
  const [createAccount, loginWithAccount] = container.getElementsByTagName("a");
  return setupLogin(createAccount, loginWithAccount);
}
function setupLogin(createAccount, loginWithAccount) {
  listenOnClick(createAccount, "showNativePaywall", window.location.href);
  listenOnClick(loginWithAccount, "loginWithExpressenAccount");
  function listenOnClick(button, fnName) {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    if (!button || !nativeApi[fnName]) return;
    button.addEventListener("click", event => {
      event.preventDefault();
      nativeApi[fnName](...args);
    });
  }
}