import { hasCMP } from "../integration/didomi";
import consentPrompt from "../plugins/consentPrompt";
let script = document.getElementById("sme-script");
let scriptIsLoaded = false;
export default function init(element, options) {
  if (!script) return;
  if (!element) return;
  const embeds = element.getElementsByClassName("js-social-embed");
  if (!embeds.length) return;
  if (!window.socialMediaEmbeds) {
    if (!window.hasOwnProperty("socialMediaEmbeds")) {
      // eslint-disable-line no-prototype-builtins
      Object.defineProperty(window, "socialMediaEmbeds", {
        configurable: true,
        set: function set(value) {
          delete window.socialMediaEmbeds;
          window.socialMediaEmbeds = value;
          runInit(value, document, options);
        }
      });
    }
    return ensureScriptIsLoaded();
  }
  runInit(window.socialMediaEmbeds, element, options);
}
function runInit(api, element) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (hasCMP()) {
    options.checkConsent = true;
    window.Exp = window.Exp || {};
    window.Exp.onConsentReady = window.Exp.onConsentReady || [];
    window.Exp.onConsentReady.push(() => {
      api(element, options, err => {
        if (!err || err.code !== "NO_CONSENT") return;
        consentPrompt({
          target: err.target,
          vendorId: err.cmpVendorId,
          className: "rich-text__block",
          callback: () => api.initWidget(err.target, options)
        });
      });
    });
  } else {
    api(element, options);
  }
}
function ensureScriptIsLoaded() {
  if (scriptIsLoaded) return;

  // This is to ensure that we always target the correct
  // script tag regardless if consent has been given
  script = document.getElementById("sme-script");
  if (!script.src) {
    script.src = script.dataset.src;
  }
  scriptIsLoaded = true;
}